<template>
	<div class="ac_tips_overlay" @click="close()">
		<div class="ac_tips">
			<div class="ac_tips_top">
				<div class="ac_tips_img">
					<img :src="item.pic" />
				</div>
				<div class="ac_tips_info">
					<div class="ac_tips_info_status">
						<div class="ac_tips_info_status_point"></div>
						<div class="ac_tips_info_status_name">{{
							item.startStatus == 0
								? "活动尚未开始"
								: item.startStatus == 1
								? "活动正在进行中"
								: "活动已结束"
						}}</div>
					</div>
					<div class="ac_tips_info_name">{{ item.title }}</div>
					<div class="ac_tips_info_line"
						>{{ item.type == 5 ? "线上活动" : "线下活动" }}
					</div>
					<div class="ac_tips_info_people" v-if="item.ticket_count > 0">
						<div class="ac_tips_info_people_header">
							<div v-for="(pic, index) in headers" :key="index">
								<img
									class="ac_people_img"
									v-if="index < item.ticket_count"
									:src="pic"
								/>
							</div>
						</div>
						<div class="ac_tips_info_people_numer">
							{{ item.ticket_count }}+ PARTICIPANTS
						</div>
					</div>
				</div>
			</div>
			<div class="ac_tips_content">
				<div class="ac_tips_content_title"> 活动详情</div>
				<div class="ac_tips_content_area">
					<div class="ac_tips_content_area_1">
						<img src="https://zy.metaera.media/assets/images/active/15_1.png" />
						<span>{{ item.address }}</span>
					</div>
					<div class="ac_tips_content_area_1">
						<img src="https://zy.metaera.media/assets/images/active/16.png" />
						<span
							>{{ formatDate(item.start_time) }} 至
							{{ formatDate(item.end_time) }} {{ item.timeZone }}</span
						>
					</div>
					<div
						class="ac_tips_content_area_1"
						v-if="item.url != undefined && item.url.length > 0"
					>
						<img src="https://zy.metaera.media/assets/images/active/17.png" />
						<span style="color: #0256ff">{{ item.url }}</span>
					</div>
				</div>
				<div class="ac_tips_content_detail">
					<p class="ql-editor" v-html="item.content"></p>
				</div>
				<div class="ac_tips_content_organizer">ORGANIZER</div>
				<div class="ac_tips_content_organizer_list">
					<img
						v-for="(item, index) in getHostArray()"
						:key="index"
						:src="item.pic"
					/>
				</div>
			</div>
			<div class="ac_tips_bottom">
				<span class="ac_tips_bottom_btn" @click="showDetail()"
					>Registration</span
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {
		item: {},
	},
	created() {},
	data() {
		return {
			headers: [
				"https://zy.metaera.media/assets/images/active/51.png",
				"https://zy.metaera.media/assets/images/active/52.png",
				"https://zy.metaera.media/assets/images/active/53.png",
				"https://zy.metaera.media/assets/images/active/54.png",
				"https://zy.metaera.media/assets/images/active/55.png",
			],
		};
	},
	methods: {
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		close() {
			this.$emit("close");
		},
		getHostArray() {
			return JSON.parse(this.item.organizer);
		},
		showDetail() {
			this.$emit("showDetail");
		},
	},
	watch: {},
};
</script>

<style scoped>
.ac_tips_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}
.ac_tips {
	width: 60%;
	min-width: 900px;
	padding: 20px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
}
</style>

<!-- ac_tips_top -->
<style scoped>
.ac_tips_top {
	display: flex;
}
.ac_tips_img {
	width: 40%;
	margin-left: 0%;
}
.ac_tips_img img {
	width: 100%;
	aspect-ratio: 16 / 9;
	border-radius: 20px;
	opacity: 1;
	object-fit: cover;
}
.ac_tips_info {
	width: 55%;
	margin-left: 20px;
	margin-top: 0px;
}
.ac_tips_info_status {
	width: 100px;
	height: 22px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 7px;
	display: flex;
	/* 主要色/主要色 */
	background: rgba(1, 86, 255, 0.1);
	justify-content: space-between;
	align-content: center;
	text-align: center;
	align-items: center;
}
.ac_tips_info_status_point {
	width: 8px;
	height: 8px;
	border-radius: 136px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_tips_info_status_name {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: 400;
	line-height: 10px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_tips_info_name {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	color: #333333;
}

.ac_tips_info_line {
	margin-top: 16px;
	display: inline-block;
	width: 163px;
	/* height: 30px; */
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 15px;
	/* 333 */
	background: white;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	/* display: flex; */
	align-items: center;
	text-align: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	/* 正文色/正文色 */
	color: #333333;
	border: 1px solid #333333;
}
.ac_tips_info_people,
.ac_tips_info_people_header {
	display: flex;
}
.ac_tips_info_people {
	margin-top: 20px;
	align-items: center;
}
.ac_tips_info_people_header img {
	box-sizing: border-box;
	border: 0.5px solid #ffffff;
	width: 26px;
	height: 26px;
	opacity: 1;
	border-radius: 9px;
	margin-right: -10px;
}

.ac_tips_info_people_numer {
	margin-left: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 10px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}
</style>

<!-- ac_tips_content -->
<style scoped>
.ac_tips_content {
	margin-top: 30px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 26px;
	padding-bottom: 26px;
	border-radius: 20px;
	background: rgba(216, 216, 216, 0.3);
}

.ac_tips_content_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}
.ac_tips_content_area {
	margin-top: 17px;
	display: flex;
	/* justify-content: space-between; */
}
.ac_tips_content_area_1 img {
	height: 16px;
	width: auto;
	opacity: 1;
}

.ac_tips_content_area_1 span {
	margin-left: 12px;
	margin-right: 30px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_tips_content_detail {
	display: flex;
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文辅助色 */
	color: #767676;
}

.ql-editor {
	max-height: 100px;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文辅助色 */
	color: #767676;
	overflow: hidden; /* 隐藏超出容器的内容 */
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 3; /* 限制最多显示两行 */
	white-space: normal; /* 允许换行 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	line-height: 1.5em; /* 设置行高 */
	padding: 0 0;
}

.ac_tips_content_organizer {
	margin-top: 60px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}

.ac_tips_content_organizer_list {
	display: flex;
	margin-top: 15px;
}

.ac_tips_content_organizer_list img {
	width: 22px;
	height: 22px;
	margin-right: 35px;
	border-radius: 11px;
	object-fit: cover;
}
</style>

<!-- ac_tips_bottom -->
<style scoped>
.ac_tips_bottom {
	margin-top: 30px;
	height: 42px;
	width: 100%;
	text-align: center;
	align-content: center;
	align-items: center;
}

.ac_tips_bottom_btn {
	border-radius: 21px;
	display: inline-block;
	/* display: flex; */
	opacity: 1;
	/* height: 42px; */
	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	padding-bottom: 15px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.ac_tips {
		width: 80%;
		min-width: 200px;
		padding: 10px;
		border-radius: 10px;
	}
	.ac_tips_top {
		display: block;
	}
	.ac_tips_img {
		width: 80%;
		margin-left: 10%;
	}

	.ac_tips_info_name {
		margin-top: 10px;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
	}

	.ac_tips_info {
		width: 100%;
		margin-left: 0%;
		margin-top: 20px;
	}

	.ac_tips_content_detail {
		display: none;
	}

	.ac_tips_content_area {
		margin-top: 4px;
		display: block;
	}
	.ac_tips_info_people {
		margin-top: 10px;
	}
	.ac_tips_content_organizer {
		margin-top: 16px;
		font-size: 12px;
	}

	.ac_tips_content_organizer_list {
		display: flex;
		margin-top: 8px;
	}

	.ac_tips_content_organizer_list img {
		margin-right: 15px;
	}
	.ac_tips_info_status {
		width: 100px;
		height: 22px;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 7px;
	}

	.ac_tips_info_line {
		margin-top: 10px;
		width: 100px;
		padding-top: 4px;
		padding-bottom: 4px;
		border-radius: 10px;
		font-size: 12px;
		font-weight: normal;
		line-height: 14px;
	}
	.ac_tips_content {
		margin-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 10px;
	}

	.ac_tips_content_title {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
	}
	.ac_tips_content_area_1 img {
		height: 10px;
		width: auto;
		opacity: 1;
	}

	.ac_tips_content_area_1 span {
		margin-left: 8px;
		margin-right: 30px;
		font-size: 12px;
		line-height: 14px;
	}

	.ac_tips_bottom {
		margin-top: 10px;
		height: 32px;
		width: 100%;
	}

	.ac_tips_bottom_btn {
		border-radius: 30px;
		font-size: 16px;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}
</style>

<style></style>
