<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<topHeader></topHeader>
			<div class="ac_slogan">
				<div class="ac_slogan_title">MetaEra 会务</div>
			</div>

			<div class="ac_banner">
				<div class="ac_slogan_subtitle">去中心化的链接， 全球化的视野。</div>
				<div class="ac_banner_content">
					<el-carousel :interval="3000" arrow="always">
						<el-carousel-item v-for="(item, index) in bannerArray" :key="index">
							<div class="ac_banner_wrapper" @click="showBannerDetail(item.id)">
								<img :src="item.pic" :alt="item.alt" class="ac_banner_image" />
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>

			<div class="ac_calendar">
				<div class="ac_calendar_header">
					<div class="ac_calendar_header_top">
						<div class="ac_calendar_header_top_left" @click="changeDate(-1)">
							<img
								src="https://zy.metaera.media/assets/images/active/5.png"
								class="ac_calendar_header_top_img"
							/>
						</div>
						<div class="ac_calendar_header_top_month">
							{{ getYearMonth(selDate) }}
						</div>
						<div class="ac_calendar_header_top_right" @click="changeDate(1)"
							><img
								src="https://zy.metaera.media/assets/images/active/4.png"
								class="ac_calendar_header_top_img"
						/></div>
					</div>
					<div class="ac_calendar_header_line"></div>
					<div class="ac_calendar_header_week">
						<div class="ac_calendar_header_week_item">SUN</div>
						<div class="ac_calendar_header_week_item">MON</div>
						<div class="ac_calendar_header_week_item">TUE</div>
						<div class="ac_calendar_header_week_item">WED</div>
						<div class="ac_calendar_header_week_item">THU</div>
						<div class="ac_calendar_header_week_item">FRI</div>
						<div class="ac_calendar_header_week_item">SAT</div>
					</div>
				</div>
				<div class="ac_calendar_content">
					<el-calendar v-model="selDate">
						<template v-slot:dateCell="{ date }">
							<div
								:class="getDateClass(date, selDate)"
								@click="handleDateClick(date)"
							>
								<div class="ac_calendar_date">{{ date.getDate() }}</div>
								<div class="ac_calendar_task">
									<!-- 在这里添加你的自定义内容 -->
									<span class="ac_calendar_task_num">{{
										getDateNum(date, selDate)
									}}</span>
									<span> TASKS</span>
								</div>
							</div>
						</template>
					</el-calendar>
				</div>
			</div>

			<div class="ac_calendar_aclist">
				<div class="ac_calendar_aclist_bg">
					<div class="ac_calendar_aclist_title">Event Overview</div>
					<div class="ac_calendar_aclist_date" style="display: none">
						{{ this.selDate.getFullYear() }}/{{
							this.selDate.getMonth() + 1
						}}/{{ this.selDate.getDate() }}
						Event Of The Day (Gmt+8)
					</div>
					<!-- 剪裁组件弹窗 -->
					<acTips
						:item="item"
						v-if="showTips"
						@close="tipClose()"
						@showDetail="showACDetail()"
					>
					</acTips>
					<div class="ac_calendar_aclist_content">
						<div
							class="ac_calendar_aclist_item pointer"
							v-for="(item, index) in activityArray"
							:key="index"
						>
							<div
								class="ac_calendar_aclist_item_content"
								@click="tipShow(item)"
							>
								<div class="ac_calendar_aclist_item_img">
									<img :src="item.pic" />
									<div class="ac_calendar_start_hour">{{
										formatTime(item.start_time)
									}}</div>
									<div
										class="ac_calendar_line"
										:class="{ ac_calendar_line_noStart: item.startStatus == 0 }"
										v-if="item.startStatus == 0"
									>
										<div> </div>
										活动尚未开始
									</div>
									<div
										class="ac_calendar_line"
										:class="{ ac_calendar_line_end: item.startStatus == 2 }"
										v-if="item.startStatus == 2"
									>
										<div> </div>
										活动已结束
									</div>
									<div
										class="ac_calendar_line"
										:class="{
											ac_calendar_line_progress: item.startStatus == 1,
										}"
										v-if="item.startStatus == 1"
									>
										<div></div>
										活动正在进行中
									</div>
								</div>
								<div class="ac_calendar_aclist_item_title">{{
									item.title
								}}</div>
								<div class="ac_calendar_aclist_item_info">
									<div class="ac_calendar_aclist_item_area">
										<img
											src="https://zy.metaera.media/assets/images/active/6.png"
										/>
										<span>{{ item.address ? item.address : "暂无地址" }}</span>
									</div>
									<div class="ac_calendar_aclist_item_date">
										<img
											src="https://zy.metaera.media/assets/images/active/7.png"
										/>
										<span
											>{{ formatDate(item.start_time) }}
											{{ item.timeZone }}</span
										>
									</div>
								</div>
								<div class="ac_calendar_aclist_item_people">
									<div class="ac_calendar_aclist_item_organizer">
										<span>ORGANIZER：</span>
										<img :src="getHostArray(item).pic" />
										<span>{{ getHostArray(item).name }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ac_calendar_article">
				<div class="ac_calendar_article_header">
					<div class="ac_calendar_aclist_title1">Event Overview</div>
				</div>
				<div class="ac_calendar_article_list">
					<div v-for="(items, indexs) in articleList" :key="indexs">
						<wzitemnew class="wzitemnew" :item="items"></wzitemnew>
						<perNews class="perNews" :item="items"></perNews>
					</div>
				</div>
			</div>
			<bottom></bottom>
			<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
		</div>
	</div>
</template>

<script>
import { getArticleList, acitvityLists, acitvityHome } from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import wzitemnew from "./../../components/pc/wzitemnew.vue";
import perNews from "./../home/mobile/perNews.vue";
import acTips from "./ac_tips.vue";

export default {
	name: "",
	components: {
		topHeader,
		wzitemnew,
		bottom,
		acTips,
		perNews,
	},
	data() {
		return {
			osafai: false,
			olead: false,
			showTips: false,
			page: 1,
			size: 25,
			item: {},
			loading: false,
			articleList: [],
			selDate: new Date(),
			bannerArray: [],
			dates: [],
			activityArray: [],
		};
	},
	created() {
		this.getArticleList({
			category_id: 0,
			type: 7,
			page: this.page,
			size: this.size,
		});
		this.getAcitvityHome();
	},
	methods: {
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
		getHostArray(item) {
			if (item.organizer && item.organizer.length > 10) {
				return JSON.parse(item.organizer)[0];
			} else {
				return { pic: "", name: "" };
			}
		},
		showBannerDetail(id) {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "ac_detail",
				query: { id: id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		tipClose() {
			this.showTips = false;
		},
		tipShow(item) {
			this.item = item;
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "ac_detail",
				query: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		showACDetail() {
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "ac_detail",
				query: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		handleScroll(event) {},
		showUrl(url) {
			window.open(url, "");
		},
		getDateClass(date, selDate) {
			if (
				date.getMonth() === selDate.getMonth() &&
				date.getDate() === selDate.getDate()
			) {
				return "selDay-date";
			} else if (date.getMonth() !== selDate.getMonth()) {
				return "noMonth-date";
			} else {
				let has = 0;
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = tmpDate.total_activities;
					}
				}
				if (has == 0) {
					return "noData-date";
				} else {
					return "numDay-date";
				}
			}
		},

		getDateNum(date, selDate) {
			if (date.getMonth() !== selDate.getMonth()) {
				return "0 ";
			} else {
				let has = "0 ";
				for (let index = 0; index < this.dates.length; index++) {
					const element = this.dates[index];
					let tmpDate = new Date(element.day);
					if (date.getDate() == tmpDate.getDate()) {
						has = element.total_activities;
					}
				}
				return has;
			}
		},
		handleDateClick(date) {
			console.log(this.selDate);

			let has = 0;
			for (let index = 0; index < this.dates.length; index++) {
				const element = this.dates[index];
				let tmpDate = new Date(element.day);
				if (this.selDate.getDate() == tmpDate.getDate()) {
					this.activityArray = element.lists;
					has = element.total_activities;
				}
			}
			if (has == 0) {
				this.activityArray = this.bannerArray;
			}

			this.getAcitvityHome();
		},
		changeDate(index) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth() + index;
			this.selDate = new Date(year, month, this.selDate.getDate());
			this.getAcitvityHome();
		},
		getYearMonth(date) {
			const year = this.selDate.getFullYear();
			const month = this.selDate.getMonth();
			const monthNames = [
				"一月",
				"二月",
				"三月",
				"四月",
				"五月",
				"六月",
				"七月",
				"八月",
				"九月",
				"十月",
				"十一月",
				"十二月",
			];
			// 			"JANUARY",
			// "FEBRUARY",
			// "MARCH",
			// "APRIL",
			// "MAY",
			// "JUNE",
			// "JULY",
			// "AUGUST",
			// "SEPTEMBER",
			// "OCTOBER",
			// "NOVEMBER",
			// "DECEMBER",
			return year + " " + monthNames[month];
		},
		getArticleList(obj) {
			let res = JSON.parse(sessionStorage.getItem("getArticleList3Data"));
			if (res != null && res.code == 200) {
				this.selarticleList = res.data.list.splice(0, 5);
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.articleList = res.data.list;
							sessionStorage.setItem(
								"getArticleList3Data",
								JSON.stringify(res)
							);
						} else {
							this.articleList = this.articleList.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					return;
				});
		},
		getAcitvityList(obj) {
			acitvityLists(obj)
				.then((res) => {
					if (res.code == 200) {
						this.activityArray = res.data.list;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					return;
				});
		},
		getAcitvityHome() {
			let obj = {
				year: this.selDate.getFullYear(),
				month: this.selDate.getMonth() + 1,
			};
			acitvityHome(obj)
				.then((res) => {
					if (res.code == 200) {
						this.bannerArray = res.data.activityList;
						this.dates = res.data.dates;

						let has = 0;
						for (let index = 0; index < this.dates.length; index++) {
							const element = this.dates[index];
							let tmpDate = new Date(element.day);
							if (this.selDate.getDate() == tmpDate.getDate()) {
								this.activityArray = element.lists;
								has = element.total_activities;
							}
						}
						if (has == 0) {
							this.activityArray = this.bannerArray;
						}

						this.activityArray = res.data.lists;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$message({ message: error.message, type: "error" });
					return;
				});
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList({
				category_id: 0,
				type: 7,
				page: this.page,
				size: this.size,
			});
		},
	},
};
</script>
<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: black;
}
</style>

<!-- slogan -->
<style scoped>
.ac_slogan {
	width: 100%;
	margin-top: 200px;
}

.ac_slogan_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 103px;
	font-weight: 600;
	line-height: 121px;
	text-align: center;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

@media screen and (max-width: 900px) {
	.ac_slogan {
		margin-top: 100px;
	}

	.ac_slogan_title {
		font-size: 53px;
		line-height: 61px;
	}
}
</style>

<!-- banner -->
<style scoped>
.ac_banner {
	margin-top: 10px;
	padding-bottom: 90px;
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_banner::before {
	content: "";
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 20%,
		rgba(0, 0, 0, 0) 80%,
		rgba(0, 0, 0, 1) 100%
	);
	pointer-events: none; /* 让伪元素不影响子元素的交互 */
}

.ac_slogan_subtitle {
	margin-top: 0px;
	margin-bottom: 80px;
	font-family: Inter;
	font-size: 24px;
	font-weight: normal;
	line-height: 31.2px;
	text-align: center;
	align-items: center;
	letter-spacing: 0em;
	position: relative;
	z-index: 1;
	color: rgba(255, 255, 255, 0.7);
}
.ac_banner_content {
	width: 60%;
	height: calc(60vw * (9 / 16));
	margin-left: 20%;
	background: white;
	border-radius: 20px;
	/* padding-bottom: 90px; */
}

.ac_banner_wrapper {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	width: 100%;
	height: 100%;
}

.ac_banner_image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 确保图片在容器中完整显示 */
	display: block;
	border-radius: 20px;
}

@media screen and (max-width: 900px) {
	.ac_banner {
		padding-bottom: 50px;
	}
	.ac_slogan_subtitle {
		margin-top: 0px;
		margin-bottom: 20px;
		font-size: 16px;
	}
	.ac_banner_content {
		width: 80%;
		height: calc(80vw * (9 / 16));
		margin-left: 10%;
		background: white;
		border-radius: 20px;
	}
}
</style>

<!-- calendar -->
<style>
.ac_calendar {
	width: 100%;
	background: black;
	display: none;
}

.ac_calendar_header {
	width: 80%;
	margin-left: 10%;
	height: 130px;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	/* Main Gradient */
	background: linear-gradient(
		97deg,
		rgba(255, 255, 255, 0.47) 2%,
		rgba(255, 255, 255, 0) 108%
	);
	border-image: radial-gradient(
			343% 163% at -1% 0%,
			rgba(255, 255, 255, 0.7) 0%,
			rgba(255, 255, 255, 0) 100%
		)
		1;

	/* Main Blur Effect */
	backdrop-filter: blur(42px);
}
.ac_calendar_header_top {
	width: 100%;
	height: 61px;
	display: flex;
	align-items: center;
	text-align: center;
	align-content: center;
	justify-content: center;
}

.ac_calendar_header_top_left,
.ac_calendar_header_top_right {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	margin-top: -8px;
}

.ac_calendar_header_top_left img,
.ac_calendar_header_top_right img {
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

.ac_calendar_header_top_month {
	margin-left: 20px;
	margin-right: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 22px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_header_line {
	width: 90%;
	height: 1px;
	margin-left: 5%;
	opacity: 0.7;
	/* 纯白 */
	background: #ffffff;
}

.ac_calendar_header_week {
	margin-left: 5%;
	margin-top: 25px;
	display: flex;
}

.ac_calendar_header_week_item {
	width: 15%;
	/* margin-left: 3%; */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_content {
	width: 80%;
	margin-left: 10%;
}
.ac_calendar .el-calendar {
	border: 0px solid #ddd;
	border-radius: 8px;
	margin-top: 30px;
	background-color: rgba(255, 255, 255, 0);
}

.ac_calendar .el-calendar__header {
	display: none;
}

.ac_calendar .el-calendar__body {
	margin-top: 10px;
	border-radius: 0px 0px 20px 20px;
	opacity: 1;
	background: linear-gradient(
		117deg,
		rgba(255, 255, 255, 0.47) 0%,
		rgba(255, 255, 255, 0) 110%
	);
	border-image: radial-gradient(
			343% 163% at -1% 0%,
			rgba(255, 255, 255, 0.7) 0%,
			rgba(255, 255, 255, 0) 100%
		)
		1;
	padding: 20px;
	/* Main Blur Effect */
	backdrop-filter: blur(42px);
}

.ac_calendar .el-calendar-table {
	width: 100%;
	border-collapse: collapse;
}

.ac_calendar .el-calendar-table thead {
	display: none;
}
.ac_calendar .el-calendar-table td {
	border: 0px solid #ddd;
	text-align: center;
}

.ac_calendar .el-calendar-table .el-calendar-day {
	padding: 5px;
	height: 120px;
}

.ac_calendar .el-calendar-table tr td:first-child {
	border-left: 0;
}

.ac_calendar .el-calendar-table tr:first-child td {
	border-top: 0;
}
.ac_calendar .el-calendar-table td.today {
	background-color: #42b983;
	color: #fff;
}

.ac_calendar .el-calendar-table td.disabled {
	color: #ccc;
}
.custom-date-cell {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.custom-content {
	margin-top: 5px;
	font-size: 12px;
	color: #666;
}

.ac_calendar_date {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 24px;
	font-weight: normal;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #454546;
}
.ac_calendar_task {
	margin-top: 20px;
}

.ac_calendar_task_num {
	margin-right: 4px;
}
.ac_calendar_task,
.ac_calendar_task span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #454546;
}

.ac_calendar .noData-date {
	height: 100%;
	padding: 18px;
	background-color: rgba(255, 255, 255, 0);
	border-radius: 10px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #939498;
}
.ac_calendar .noData-date .ac_calendar_date {
	color: #aaaaaa;
}

.ac_calendar .noData-date .ac_calendar_task {
	color: #aaaaaa;
}

.ac_calendar .noMonth-date {
	height: 100%;
	border-radius: 10px;
	box-sizing: border-box;
	border: 2px solid rgba(0, 0, 0, 0.2);
	padding: 18px;
}

.ac_calendar .noMonth-date .ac_calendar_date {
	color: #454546;
}

.ac_calendar .noMonth-date .ac_calendar_task {
	color: #454546;
}

.el-calendar-table td.is-selected,
.el-calendar-table .el-calendar-day:hover {
	background-color: rgba(255, 255, 255, 0);
}
.ac_calendar .selDay-date {
	height: 100%;
	padding: 18px;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
	box-sizing: border-box;
	/* 纯白 */
	border: 2px solid #ffffff;
}

.ac_calendar .numDay-date {
	height: 100%;
	padding: 18px;
	border-radius: 10px;
	opacity: 0.5;
	background: #f5f6ff;
	box-sizing: border-box;
	/* 主要色/主要色背景 */
	border: 2px solid #e0eaff;
}

.ac_calendar .numDay-date .ac_calendar_date {
	color: #1a1a1a;
}

.ac_calendar .numDay-date .ac_calendar_task {
	color: #000000;
	display: flex;
}

@media screen and (max-width: 900px) {
	.ac_calendar .el-calendar-table .el-calendar-day {
		padding: 5px;
		height: 80px;
	}

	.ac_calendar_date {
		width: 100%;
		font-size: 12px;
		line-height: 14px;
		display: inline-block;
		align-content: center;
		text-align: center;
		align-items: center;
	}
	.ac_calendar .noMonth-date {
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		padding: 0px;
	}

	.ac_calendar .numDay-date {
		padding: 0px;
		border-radius: 4px;
		opacity: 0.5;
		/* 主要色/主要色背景 */
		border: 1px solid #e0eaff;
	}

	.ac_calendar .selDay-date {
		padding: 0px;
		border-radius: 4px;
		/* 纯白 */
		border: 1px solid #ffffff;
	}

	.ac_calendar_task {
		display: table-cell;
		margin-top: 0px;
	}

	.ac_calendar_task span {
		display: inline-block;
		text-align: center;
		align-content: center;
		align-items: center;
		font-size: 10px;
	}

	.ac_calendar_header {
		width: 90%;
		margin-left: 5%;
		height: 80px;
	}

	.ac_calendar_header_top {
		height: 45px;
	}

	.ac_calendar_header_week {
		margin-top: 10px;
	}

	.ac_calendar_header_top_month {
		font-size: 18px;
	}
	.ac_calendar_content {
		width: 90%;
		margin-left: 5%;
	}

	.ac_calendar .el-calendar__body {
		margin-top: 10px;
		padding: 10px;
		padding-top: 20px;
	}
	.ac_calendar .el-calendar {
		margin-top: 10px;
	}
}
</style>

<!-- ac_calendar_aclist -->
<style scoped>
.ac_calendar_aclist {
	width: 100%;
	/* margin-top: 90px; */
	/* background: url(https://zy.metaera.media/assets/images/active/12.png); */
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.ac_calendar_aclist_bg {
	width: 80%;
	margin-left: 10%;
	margin-top: 90px;
}

.ac_calendar_aclist_title {
	font-family: "Bison-Bold";
	font-size: 60px;
	line-height: 60px;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: center;
	align-content: center;
	color: #ffffff;

	/* font-variation-settings: "opsz" auto;

	
	text-decoration: underline;
	text-decoration-color: #0256ff;
	text-decoration-thickness: 4px;
	text-underline-offset: 10px; */
}

.ac_calendar_aclist_title1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: black;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}
.ac_calendar_aclist_date {
	margin-top: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_content {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 60px;
	margin-left: -2%;
	width: 104%;
}
.ac_calendar_aclist_item {
	margin-top: 50px;
	margin-left: 1.6%;
	margin-right: 1.6%;
	flex: 1 0 30%;
	box-sizing: border-box;
	flex-grow: 0;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	padding: 12px;

	background: linear-gradient(
		135deg,
		rgba(255, 255, 255, 0.47) 0%,
		rgba(255, 255, 255, 0) 112%
	);
}

.ac_calendar_aclist_item::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 20px;
	z-index: 0;
}

.ac_calendar_aclist_item_content {
	position: relative;
	z-index: 1;
}

.ac_calendar_aclist_item_img {
	position: relative;

	border-radius: 10px;
	/* padding: 12px; */
}

.ac_calendar_aclist_item_img img {
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}

.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line {
	top: 10px; /* 距离顶部的距离 */
	right: 10px; /* 距离右边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #0256ff;
}

.ac_calendar_line_end {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: gray;
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #08bf66;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}

.ac_calendar_aclist_item_title {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(14px, calc(100vw * 16 / 1280), 18px);
	font-weight: 400;
	line-height: 24px;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_info {
	margin-top: 6px;
	display: block;
	justify-content: space-between;
}
.ac_calendar_aclist_item_area,
.ac_calendar_aclist_item_date {
	margin-top: 6px;
}

.ac_calendar_aclist_item_info span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 14px);
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_info img {
	width: auto;
	height: 15px;
	margin-right: 10px;
}

.ac_calendar_aclist_item_people {
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
}
.ac_calendar_aclist_item_people span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: normal;
	line-height: 10px;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.ac_calendar_aclist_item_people img {
	margin-left: 10px;
	width: 18px;
	height: 18px;
	margin-right: -10px;
	border-radius: 9px;
	object-fit: cover;
}

.ac_calendar_aclist_item_organizer img {
	margin-right: 4px;
}

@media screen and (max-width: 900px) {
	.ac_calendar_aclist {
		margin-top: 10px;
	}
	.ac_calendar_aclist_bg {
		width: 90%;
		margin-left: 5%;
		margin-top: 20px;
	}
	.ac_calendar_aclist_title {
		font-size: 20px;
		line-height: 30px;
		text-decoration-thickness: 2px; /* 设置下划线高度 */
		text-underline-offset: 6px; /* 调整下划线与文字的距离 */
	}

	.ac_calendar_aclist_date {
		margin-top: 20px;
		font-size: 18px;
	}

	.ac_calendar_aclist_content {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 60px;
		margin-left: -0%;
		width: 100%;
	}

	.ac_calendar_aclist_item {
		margin-top: 30px;
		margin-left: 0%;
		margin-right: 0%;
		flex: 1 0 100%;
		border-radius: 20px;
		padding: 12px;
	}

	.ac_calendar_aclist_item_info img {
		width: auto;
		height: clamp(10px, calc(100vw * 12 / 414), 14px);
		margin-right: 6px;
	}
}
</style>

<!-- ac_calendar_article -->
<style scoped>
.ac_calendar_article {
	width: 100%;
	background: white;
	padding-bottom: 80px;
}

.ac_calendar_article_header {
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 60px;
	display: flex;
}
.ac_calendar_article_header_1 {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_calendar_article_header_2 {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	border-radius: 4px;
	opacity: 1;

	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}
.ac_calendar_article_list {
	margin-left: 10%;
	margin-top: 20px;
	width: 80%;
}
.wzitemnew {
	display: flex;
}
.perNews {
	display: none;
}
@media screen and (max-width: 900px) {
	.wzitemnew {
		display: none;
	}
	.perNews {
		display: contents;
	}
	.ac_calendar_article_header {
		margin-left: 5%;
		width: 90%;
		padding-top: 40px;
	}
	.ac_calendar_article_list {
		margin-left: 5%;
		width: 90%;
	}

	.ac_calendar .numDay-date .ac_calendar_task {
		color: #000000;
		display: grid;
	}
}
</style>
<style>
.ac_banner_content .el-carousel__container {
	height: calc(60vw * (9 / 16)) !important;
}

@media screen and (max-width: 900px) {
	.ac_banner_content .el-carousel__container {
		height: calc(80vw * (9 / 16)) !important;
	}
}
</style>
