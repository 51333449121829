<template>
	<div
		class="wzitem_sub"
		@click="
			$router.push({
				path: '/gw_detail',
				query: { id: item.id },
			})
		"
	>
		<div
			class="wzitem_sub_Right"
			:class="{
				wzitem_sub_Right_show: item.pic != null && item.pic.length > 0,
			}"
		>
			<img
				class="wzitem_sub_img pointer"
				v-lazy="item.pic"
				:key="item.pic"
				alt=""
			/>
		</div>
		<div
			class="wzitem_sub_Left"
			:class="{ reItem_Left_full: item.pic != null && item.pic.length > 0 }"
		>
			<div class="wzitem_sub_Left_title hovercolor pointer">{{
				localLanguage == 2 ? (item.title ? item.title : item.title) : item.title
			}}</div>
			<div class="wzitem_sub_Left_content hovercolor pointer">
				{{
					localLanguage == 2
						? item.illustrate_en
							? item.illustrate_en
							: item.illustrate
						: item.illustrate
				}}
			</div>
			<div class="wzitem_sub_Left_bottom">
				<div class="wzitem_sub_Left_line"></div>
				<div class="wzitem_sub_Left_author">{{ item.author.nickname }}</div>
				<div class="wzitem_sub_Left_time">{{ item.release_time }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "./leftTab";

export default {
	props: ["item"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			// 处理收到的通知，并更新组件状态
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {},
};
</script>

<style scoped>
.wzitem_sub {
	display: flex;
	min-height: 140px;
	margin-top: 10px;
}
.wzitem_sub_Left {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.reItem_Left_full {
	width: 100%;
}

.wzitem_sub_Left_title {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-right: 10px;
	margin-top: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;

	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;

	font-size: 20px;
}
.wzitem_sub_Left_content {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0em;

	/* 正文色/正文辅助色 */
	color: #767676;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.wzitem_sub_Left_bottom {
	margin-top: auto;
	display: flex;
	height: 15px;
	flex: 0 1 auto;
}
.wzitem_sub_Left_time,
.wzitem_sub_Left_author {
	margin-right: 30px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	color: #b2b2b2;
}
.wzitem_sub_Right {
	display: none;
}

.wzitem_sub_Right_show {
	display: block;
	margin-right: clamp(10px, calc(100vw * 10 / 1280), 20px);
}
.wzitem_sub_img {
	width: clamp(213px, calc(100vw * 213 / 1280), 320px);
	/* height: 180px; */
	height: clamp(120px, calc(100vw * 120 / 1280), 180px);
	border-radius: 18px;
	margin-top: 20px;
	object-fit: cover;
}

.wzitem_sub_Left_line {
	margin-left: clamp(10px, calc(100vw * 10 / 1280), 20px);
	margin-right: 7px;
	bottom: 0px;
	width: 2px;
	height: 12px;
	border-radius: 24px;
	opacity: 1;
	background: #b6b9cc;
}
</style>
